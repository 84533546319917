<template>
  <posts-index />
</template>

<script>
import PostsIndex from '../components/posts/Index'

export default {
  name: 'PostsPageIndex',
  components: { PostsIndex }
}
</script>
